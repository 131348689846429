import "modern-normalize/modern-normalize.css"
import "./src/css/variables.css"
import "./src/css/styles.css"
import React from "react"
import axios from "axios"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
      cacheTime: 60000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
axios.defaults.baseURL = process.env.GATSBY_API_URL
axios.defaults.withCredentials = true

// get CSRF Cookie
// this is mainly used for form submissions
axios.get("/sanctum/csrf-cookie")

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    {element}
    <ToastContainer />
  </QueryClientProvider>
)
