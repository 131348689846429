import * as React from "react"
import { LocaleProvider } from "./context"
import { Seo } from "./components/seo"

const wrapPageElement = ({ element, props }) => (
  <LocaleProvider pageContext={props.pageContext}>
    <Seo location={props.location} pageContext={props.pageContext} />
    {element}
  </LocaleProvider>
)

export { wrapPageElement }
